<template>
  <section>
    <div class="home-sub-title text-center">
      <p>5501 Adams ST Unit C Matteson, IL 60443</p>
    </div>
    <div class="home-welcome text-center">
      <h2>Islamic Financing</h2>
    </div>
  </section>
  <section class="second-section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-8 mx-auto">
          <p class="mb-3">
            <span class="fw-bold">E & A offers</span> Sharia compliant loans. We
            understand Islamic values and support their growing demographic in
            the trucking industry. We provide socially responsible loans in
            accordance to these values.
          </p>
          <p class="fw-bold">
            Contact Anton Churkin to hear more about our programs.
          </p>
          <p class="mb-3">
            Anton Churkin –
            <span class="fw-bold">eandatrucksales@gmail.com</span>
          </p>
          <p>Call E & A Trucks: (312) 678-0974</p>
        </div>
      </div>
    </div>
  </section>
  <base-footer></base-footer>
</template>

<script>
export default {};
</script>

<style scoped></style>
